@import "bootstrap/dist/css/bootstrap.min.css";
body {
  margin: 0;
  font-size:20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-image:url(./assets/image/sumomonga.png);
  background-repeat:repeat;
  background-color:rgba(255,255,255,0.8);
  background-blend-mode:lighten;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.dropdown-menu li {
	position: relative;
}
.dropdown-menu .dropdown-submenu {
	display: none;
	position: absolute;
	left: 100%;
	top: -7px;
}
.dropdown-menu .dropdown-submenu-left {
	right: 100%;
	left: auto;
}
.dropdown-menu > li:hover > .dropdown-submenu {
	display: block;
}
span.alert-primary{
  padding:0px;
}